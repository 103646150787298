@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.moving-gradient {
  background-size: 200% 200%;
  animation: gradientMove 5s ease infinite;
}
@keyframes subtleGradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.subtle-moving-gradient {
  background: linear-gradient(270deg, #ff0076, #590fb7, #1d89f2, #24c6dc);
  background-size: 150% 150%; /* Adjust for subtle movement */
  animation: subtleGradientMove 10s ease infinite; /* Slow, smooth movement */
}

@keyframes gradientMoveHorizontal {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}
.animate-fadeIn {
  animation: fadeIn 0.4s ease forwards;
}
@keyframes checkPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.animate-checkPop {
  animation: checkPop 1.4s ease-in-out forwards;
}
@keyframes checkPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.animate-checkPop {
  animation: checkPop 1.4s ease-in-out forwards;
}
/* index.css (or similar) */
@keyframes checkPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.animate-checkPop {
  animation: checkPop 1.4s ease-in-out forwards;
}
